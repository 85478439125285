// src/components/UseTerms.js

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const UseTermsDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>이용약관</DialogTitle>
      <DialogContent>
        <Typography variant="body1" component="div">
          제1조 (목적)<br />
          이 약관은 pixy('회사')가 제공하는 무인매장 서비스('서비스')의 이용과 관련하여 회사와 이용자의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.<br/><br/>
          제2조 (정의)<br/><br />
          1. '서비스'라 함은 회사가 운영하는 무인매장 및 관련 제반 서비스를 의미합니다.<br/>
          2. '이용자'라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.<br/>
          3. '회원'이라 함은 회사에 개인정보를 제공하여 회원 등록을 한 자로서, 회사가 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다.<br/>
          4. '비회원'이라 함은 회원에 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.<br/><br/>
          제3조 (약관의 명시, 효력 및 개정)<br/><br/>
          1. 회사는 이 약관의 내용을 이용자가 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다.<br/>
          2. 회사는 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br/>
          3. 회사가 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여 현행 약관과 함께 서비스 초기 화면에 그 적용 일자 7일 전부터 공지합니다.<br/>
          4. 이용자는 변경된 약관에 동의하지 않을 권리가 있으며, 동의하지 않을 경우 서비스 이용을 중단하고 회원 탈퇴를 요청할 수 있습니다.<br/><br/>
          제4조 (서비스의 제공 및 변경)<br/><br/>
          1. 회사는 다음과 같은 서비스를 제공합니다.<br/>
              &nbsp;&nbsp;- 무인매장 운영 및 관리<br/>
              &nbsp;&nbsp;- CCTV를 통한 매장 내 활동 감지<br/>
              &nbsp;&nbsp;- 판매량 예측 및 분석<br/>
              &nbsp;&nbsp;- 기타 회사가 정하는 서비스<br/>
          2. 회사는 서비스의 내용 및 제공 방식을 변경할 수 있으며, 이 경우 변경된 서비스의 내용 및 제공 일자를 명시하여 공지합니다.<br/><br/>
          제5조 (서비스의 중단)<br/><br/>
          1. 회사는 다음 각 호에 해당하는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다.<br/>
              &nbsp;&nbsp;- 시스템 점검, 유지보수 또는 교체 등 기술적인 필요에 의해 사전에 공지한 경우<br/>
              &nbsp;&nbsp;- 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우<br/>
              &nbsp;&nbsp;- 기타 불가항력적 사유가 발생한 경우<br/>
          2. 회사는 서비스의 제공이 중단되는 경우 이용자에게 사전 통지하며, 부득이한 사유로 사전 통지가 불가능한 경우 사후에 지체 없이 통지합니다.<br/><br/>
          제6조 (회원 가입)<br/><br/>
          1. 이용자는 회사가 정한 가입 양식에 따라 회원 정보를 기입한 후 이 약관에 동의함으로써 회원 가입을 신청합니다.<br/>
          2. 회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.<br/>
              &nbsp;&nbsp;- 가입 신청자가 이 약관에 의하여 이전에 회원 자격을 상실한 적이 있는 경우<br/>
              &nbsp;&nbsp;- 등록 내용에 허위, 기재누락, 오기가 있는 경우<br/>
              &nbsp;&nbsp;- 기타 회원으로 등록하는 것이 회사의 기술상 현저히 지장이 있다고 판단되는 경우<br/><br/>
          제7조 (회원 탈퇴 및 자격 상실 등)<br/><br/>
          1. 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며, 회사는 즉시 회원 탈퇴를 처리합니다.<br/>
          2. 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원 자격을 제한 및 정지시킬 수 있습니다.<br/>
              &nbsp;&nbsp;- 가입 신청 시에 허위 내용을 등록한 경우<br/>
              &nbsp;&nbsp;- 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우<br/>
              &nbsp;&nbsp;- 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br/><br/>
          제8조 (회원에 대한 통지)<br/><br/>
          1. 회사가 회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.<br/>
          2. 회사는 불특정다수 회원에 대한 통지의 경우 1주일 이상 서비스 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다.<br/><br/>
          제9조 (개인정보 보호)<br/>
          회사는 관련 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력하며, 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다.<br/><br/>
          제10조 (회사의 의무)<br/><br/>
          1. 회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 이 약관이 정하는 바에 따라 지속적이고 안정적으로 서비스를 제공하는 데 최선을 다합니다.<br/>
          2. 회사는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위해 보안 시스템을 갖추어야 합니다.<br/>
          3. 회사는 서비스 제공과 관련하여 알게 된 이용자의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않고, 이를 보호하기 위해 노력합니다.<br/><br/>
          제11조 (이용자의 의무)<br/><br/>
          1. 이용자는 다음 행위를 하여서는 안 됩니다.<br/>
              &nbsp;&nbsp;- 신청 또는 변경 시 허위 내용의 등록<br/>
              &nbsp;&nbsp;- 타인의 정보 도용<br/>
              &nbsp;&nbsp;- 회사에 게시된 정보의 변경<br/>
              &nbsp;&nbsp;- 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시<br/>
              &nbsp;&nbsp;- 회사 및 기타 제3자의 저작권 등 지적 재산권에 대한 침해<br/>
              &nbsp;&nbsp;- 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
              &nbsp;&nbsp;- 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위<br/><br/>
          2.이용자는 관계 법령, 이 약관의 규정, 이용 안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.<br/><br/>
          제12조 (저작권의 귀속 및 이용 제한)<br/><br/>
          1. 회사가 작성한 저작물에 대한 저작권 기타 지적 재산권은 회사에 귀속합니다.<br/>
          2. 이용자는 서비스를 이용함으로써 얻은 정보 중 회사에 지적 재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.<br/><br/>
          제13조 (약관의 해석)<br/><br/>
          1. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계 법령 또는 상관례에 따릅니다.<br/><br/>
          제14조 (분쟁 해결)<br/><br/>
          1. 회사는 이용자로부터 제출되는 불만사항 및 의견을 우선적으로 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 즉시 통보합니다.<br/>
          2. 이 약관과 관련하여 회사와 이용자 간에 발생한 분쟁에 대한 소송은 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UseTermsDialog;
